import * as React from 'react';
import { animated, useSpring } from 'react-spring';
import styled from 'styled-components';
import { useImageLoaded } from '../../../utils/hooks/useImageLoaded';

interface IImageCarouselBullets {
  currentIndex: number;
  select: (index: number) => void;
  images: { url?: string }[];
  className?: string;
}

export const ImageCarouselBulletsWithNoAnimation: React.FC<
  IImageCarouselBullets
> = ({ images, select, currentIndex, className }) => {
  return (
    <Bullets className={className}>
      {images.map((item: any, index) => {
        return (
          <Bullet
            key={item.url}
            onClick={() => select(index)}
            className={index === currentIndex ? 'target' : 'bullett'}
          ></Bullet>
        );
      })}
    </Bullets>
  );
};

export const Image: React.FC<{ url: string; className?: string }> = ({
  url,
  className
}) => {
  const { loaded } = useImageLoaded({
    url
  });
  const props = useSpring({
    from: {
      opacity: 0
    },
    to: {
      opacity: loaded ? 1 : 0
    }
  });
  return (
    <StyledImage className={className} style={props}>
      <img src={loaded ? url : ''} data-url={url} alt="" />
    </StyledImage>
  );
};

const StyledImage = styled(animated.div)<{ className?: string }>`
  img {
    transition: opacity 0.5s ease-in-out 0s;
    width: 100%;
    height: 700px;
    object-fit: contain;
  }
`;

export const Bullets = styled.div`
  position: absolute;
  width: 100%;
  bottom: 0;
  max-width: 200px;
  left: 0;
  right: 0;
  text-align: center;
  margin: 0 auto;
`;
export const Bullet = styled.div`
  width: 0.8em;
  height: 0.8em;
  border-radius: 50%;
  display: inline-block;
  margin: 0 10px;
  &.target {
    background: linear-gradient(
      to top,
      rgb(131, 70, 40),
      rgb(215, 168, 124),
      rgb(156, 96, 63),
      rgb(154, 94, 61)
    ) !important;
  }
  &.bullett {
    background: rgb(72, 72, 78);
  }
`;
