import * as React from 'react';
import styled from 'styled-components';

export const CarouselButtonLeft: React.FC<{
  onClick?: () => void;
  className?: string;
}> = ({ onClick, className }) => {
  return (
    <CarouselButtonLeftStyle className={className} onClick={onClick}>
      {/*       <svg
        width="18"
        height="32"
        viewBox="0 0 18 32"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M1.01614 31.4218C0.766146 31.1718 0.5625 30.9462 0.5625 30.9206C0.5625 30.8949 3.83329 27.6036 7.83179 23.606C11.8293 19.6085 15.1011 16.3158 15.1011 16.2902C15.1011 16.2646 11.8303 12.972 7.83179 8.97442C3.83422 4.97686 0.5625 1.67329 0.5625 1.63427C0.5625 1.54281 1.4503 0.65625 1.54174 0.65625C1.57954 0.65625 5.12347 4.16836 9.41604 8.46094L17.2207 16.2656L9.41604 24.0703C5.12347 28.3629 1.57951 31.875 1.54049 31.875C1.50147 31.875 1.2661 31.6701 1.01611 31.4201L1.01614 31.4218Z"
          fill="white"
        />
      </svg> */}
    </CarouselButtonLeftStyle>
  );
};

/* Karussel knapp venstre */
const CarouselButtonLeftStyle = styled.button`
  position: absolute;
  top: 0;
  bottom: 0;
  /*   right: 30px; */
  margin: auto;
  width: 1.125em;
  height: 2.25em;
  background: url(https://reeltimeprod-ahebbuatfggte7f2.z01.azurefd.net/privatmegleren/arrow.svg)
    center center / contain no-repeat;
  border: none;
  appearance: none;
  border-radius: 0;
  transform: rotate(360deg);
  z-index: 1;
  &:hover {
    cursor: pointer;
  }
  svg {
    position: relative;
    top: 2px;
  }

  @media all and (max-width: 600px) {
    right: 15px;
  }
`;
