import { useRef, useState, useEffect } from 'react';
interface IUseImageLoaded {
  url?: string;
}
export const useImageLoaded = (args: IUseImageLoaded) => {
  let { current: image } = useRef<HTMLImageElement | null>(null);
  const [loaded, setLoaded] = useState(false);
  const handleLoaded = () => {
    if (!loaded) {
      setLoaded(true);
    }
  };
  useEffect(() => {
    if (!image) image = new Image(0, 0);
    if (image && args.url) image.src = args.url;
    if (image?.src) image?.addEventListener('load', handleLoaded);
    return () => {
      image?.removeEventListener('load', handleLoaded);
      image = null;
    };
  }, [image, args.url]);
  return { loaded };
};
