import React from 'react';
import styled from 'styled-components';
import { Heading } from '../../ui/text/Heading';
import { Paragraph } from '../../ui/text/Paragraph';
import { Container } from '../../layout/Container';
import { useFormTransition } from '../../../utils/hooks/useFormTransition';
import { createLeadMutation } from '../../../../client/__graphql__/mutations';
import { useMutation } from '@apollo/client';
import { ILeadInput, ILeadResponse } from '../../../__types__/global';
import { useForm } from '../../../utils/hooks/useForm';
import { Form, FormRow, FormWrapper } from '../../ui/form/Form';
import { FormError } from '../../ui/form/FormError';
import { Input } from '../../ui/form/Input';
import { Textarea } from '../../ui/form/Textarea';
import { ButtonGroup } from '../../ui/button/ButtonGroup';
import { Button } from '../../ui/button/Button';
import { ButtonLink } from '../../ui/link/ButtonLink';
import { SiteContext } from '../../../utils/context/SiteContext';
import { routeConfig } from '../../../__config__/routes';
import { Seo } from '../../../utils/seo/Index';
import { Thanks } from '../../layout/Thanks';
import { Section } from '../../layout/Section';
import { animated } from 'react-spring';
import { CarouselButtonRight } from '../../ui/button/ButtonCarouselRight';
import {
  Image,
  ImageCarouselBulletsWithNoAnimation
} from '../../ui/carousel/ImageCarouselBulletsWithNoAnimation';
import { CarouselButtonLeft } from '../../ui/button/ButtonCarouselLeft';
import { Hero, HeroContent } from '../../ui/hero/Index';
import { Logo } from '../../ui/logo/office/Index';
import Space from '../../ui/space/Space';

const Saedberg: React.FC = () => {
  const { routeConfig }: any = React.useContext(SiteContext);
  const [posted, setPosted] = React.useState(false);

  const { fadeIn, fadeOut } = useFormTransition({
    posted
  });

  const MAIL_MUTATION = createLeadMutation({
    mail: {
      response: `{
              success
            }`
    }
  });

  const [mail, { loading, error }] = useMutation<ILeadResponse, ILeadInput>(
    MAIL_MUTATION,
    {
      onCompleted: ({ lead }) => {
        if (lead.mail?.success) {
          setPosted(true);
        }
      }
    }
  );

  const { fields, getFields, handleSubmit, handleChange } = useForm({
    fields: [
      {
        type: 'text',
        name: 'name',
        placeholder: 'Eg. Ola Nordmann',
        value: '',
        label: 'Name *',
        required: true
      },
      {
        type: 'text',
        name: 'zip',
        placeholder: 'Eg. 0000',
        value: '',
        label: 'Zip *',
        required: true
      },
      /*       {
        type: 'text',
        name: 'address',
        placeholder: 'Eks. Adresseveien 23',
        value: '',
        label: 'Adresse *',
        required: true
      }, */
      {
        type: 'tel',
        name: 'phone',
        placeholder: 'Eg. 00000000',
        value: '',
        label: 'Phone *',
        labelAfter: '(Used to contact you)',
        required: true
      },
      {
        type: 'email',
        name: 'email',
        placeholder: 'Eg. ole@mail.no',
        value: '',
        label: 'E-mail *',
        labelAfter: '(Used to contact you)',
        required: true
      }
    ],
    submit: () => {
      const preparedFields: any = getFields(fields);
      mail({
        variables: {
          input: {
            ...preparedFields,
            referer: window.location.href,
            leadType: 'EPOST',
            mailType: 117
          }
        }
      });
    }
  });

  return (
    <MaripaaveienStyle>
      <Seo
        title="Welcome to Skipanesvegen 264 - A truly special property with a newly built villa and spectacular views, as well as its own shoreline and pier."
        description="6 bedrooms | 4 baths | 1 half bath | Single Family Home. With its seaside location in a highly sought-after and exclusive area in Hjellestad, this dream home is exceptionally well-placed."
        image="https://reeltimeprod-ahebbuatfggte7f2.z01.azurefd.net/privatmegleren/fjellsiden_3_comp.jpg"
      />
      <Hero
        className="content-height-1000 padding-top-50 padding-bottom-80 height hero-content-flex-bottom"
        size="cover"
        position="bottom"
        imageUrl={
          'https://reeltimeprod-ahebbuatfggte7f2.z01.azurefd.net/privatmegleren/fjellside_front_ny_comp.jpg'
        }
        opacity={0.6}
        alt="bilde"
      >
        <HeroContentWrapper className="">
          <HeroContent>
            <Space style={{ minHeight: '280px' }} />
            <Heading tag="h1" center={true} className="solgte">
              Skipanesvegen 264 <br /> Hjellestad
            </Heading>
          </HeroContent>

          <DescriptionInsideTheHeroContainer></DescriptionInsideTheHeroContainer>
        </HeroContentWrapper>
      </Hero>

      <Container>
        <Space style={{ minHeight: '2em' }} />
        <Paragraph center={true}>Norway – Bergen</Paragraph>{' '}
        <Paragraph center={true}>
          10.900.000 EUR <br />
          Skipanesvegen, 5259 Hjellestad
        </Paragraph>{' '}
        <Ingress>
          <Paragraph center={true}>
            6 bedrooms | 4 baths | 1 half bath | Single Family Home
          </Paragraph>
        </Ingress>
        <Heading tag="h3" center={true}>
          PROPERTY DESCRIPTION
        </Heading>
        <Paragraph center={true}>
          Welcome to Skipanesvegen 264 - A truly special property with a newly
          built villa and spectacular views, as well as its own shoreline and
          pier.
        </Paragraph>
        <Paragraph center={true}>
          The property is located in a quiet and private residential area,
          offering complete privacy to its occupants as the property is gated
          and located at the end of a road used only by three other neighboring
          properties. It offers a peaceful and private atmosphere, while all
          amenities are just a short drive away.
        </Paragraph>
        <Paragraph center={true}>
          With its large spacious rooms, high ceilings, and timeless decoration,
          the property can comfortably accommodate a large family or multiple
          guests. Parts of the lower floor can easily be closed off as a
          separate guest- or caretaker/assistant apartment, offering the
          possibility for complete privacy for the owners.
        </Paragraph>
        <Paragraph center={true}>
          The extensive landscaped garden is equipped with a heated swimming
          pool.
        </Paragraph>
        <Paragraph center={true}>
          With its unique location, this magnificent property has a fantastic
          view over Djupevika and Fanafjorden. The property is beautifully
          landscaped with a garden featuring elements of both rock, heather, and
          sedum, in addition to the well-maintained lawn. Impeccable sunlight
          conditions can be enjoyed year-round. The property is surrounded by
          beautiful landscapes and green areas, creating the perfect setting for
          outdoor entertainment and relaxation.
        </Paragraph>
        <Paragraph center={true}>
          Bergen city center is about 30 minutes away by car, and there is
          walking distance to the the nearest bus stop. It takes about 15
          minutes to get to Flesland Airport.
        </Paragraph>
        <Paragraph center={true}>
          The property's unique location provides immediate access to beautiful
          hiking opportunities. A short walk away, you'll find the Bergen
          Botanical Garden, including Bergen's only public Japanese garden. You
          can also take a walk around Mildevatnet, starting at the parking lot
          of the Botanical Garden, near Vågeelva. Another option is the outdoor
          area near the Arboretum with sheltered coves, a small beach, and
          hiking trails - perfect for a nice Sunday walk. If you're into
          maritime activities, the area is one of the best in all of Bergen.
        </Paragraph>
        <Heading tag="h3" center={true}>
          PROPERTY DETAILS
        </Heading>
        <Paragraph center={true}>
          Interior primary area (P-rom): 437 sqm, usable area (Bruksareal): 562
          sqm Exterior: 11 366 sqm Year built: 2023
        </Paragraph>
        <Paragraph center={true}>
          Lower floor: Living room with fireplace, living room/kitchen, 2
          bedrooms, 2 bathrooms, fitness room, sauna, laundry/hall, hallway, and
          technical room. Main floor/1st floor: Hall, guest wardrobe, wardrobe,
          laundry room, living room/kitchen, utility kitchen, gallery, hallway,
          4 bedrooms, WC, 2 bathrooms, and walk-in wardrobe
        </Paragraph>
        <Heading tag="h3" center={true}>
          FOR MORE INFORMATION
        </Heading>
        <Paragraph center={true}>
          Tom Jørgensen Licensed real estate salesperson
          <br />
          Mob: <a href="tel:+4792806459">92806459</a>
          <br />
          E-mail:{' '}
          <a href="mailto:tom@privatmegleren.no">tom@privatmegleren.no</a>
          <br />
        </Paragraph>
        <ImageCarousel />
        <FormWrapper style={fadeOut}>
          <Heading tag="h1" center={true} style={{ paddingTop: '20px' }}>
            Contact Us
          </Heading>
          <Form
            onSubmit={handleSubmit}
            noValidate
            style={{ paddingBottom: '50px' }}
          >
            {error &&
              error.graphQLErrors.map((error, index) => (
                <FormError key={`error_${index}`} message={error.message} />
              ))}
            {fields
              .filter((item) => item?.type !== 'textarea')
              .map((item, index) => {
                return (
                  <FormRow key={`field_${item?.name}`}>
                    <Input
                      type={item?.type}
                      name={item?.name}
                      label={item?.label}
                      labelAfter={item?.labelAfter}
                      placeholder={item?.placeholder}
                      value={item?.value}
                      onChange={handleChange}
                      error={item?.error}
                    />
                  </FormRow>
                );
              })}
            <ButtonGroup>
              <Button
                type="primary"
                colorTheme="gold"
                disabled={loading}
                loading={loading}
              >
                Contact me
              </Button>
              <ButtonLink
                as="a"
                href={routeConfig.personvern.url}
                className="privacy-link-mobile"
              >
                Privacy policy
              </ButtonLink>
            </ButtonGroup>
          </Form>
        </FormWrapper>
        <Thanks style={fadeIn}>
          <Section className="form-section">
            <Heading tag="h2" center={true}>
              Thank you
            </Heading>
            <Paragraph center={true}>
              A real estate agent will contact you as soon as possible.
            </Paragraph>
          </Section>
        </Thanks>
      </Container>
    </MaripaaveienStyle>
  );
};

const MaripaaveienStyle = styled.div`
  padding-top: 7em;
  .saedberg {
    object-fit: contain;
    width: 100%;
    padding: 2em 0 2em;
  }

  @media all and(min-width:600px) {
    .saedberg {
      object-fit: contain;
      width: 100%;
      padding: 3em;
    }
  }
`;

const Info = styled.div`
  li {
    color: ${({ theme }) => theme.colors.primary};
  }
`;

const ImageCarousel: React.FC = () => {
  const [currentIndex, setCurrentIndex] = React.useState(0);

  const prev = () => {
    setCurrentIndex((prev) => (prev - 1 < 0 ? array.length - 1 : prev - 1));
  };
  const next = () => {
    setCurrentIndex((prev) => (prev + 1 > array.length - 1 ? 0 : prev + 1));
  };

  const select = (index: number) => {
    setCurrentIndex(index);
  };
  const array = [
    {
      url: 'https://reeltimeprod-ahebbuatfggte7f2.z01.azurefd.net/privatmegleren/1f.jpg'
    },
    {
      url: 'https://reeltimeprod-ahebbuatfggte7f2.z01.azurefd.net/privatmegleren/2f.jpg'
    },
    {
      url: 'https://reeltimeprod-ahebbuatfggte7f2.z01.azurefd.net/privatmegleren/2fa.jpg'
    },
    {
      url: 'https://reeltimeprod-ahebbuatfggte7f2.z01.azurefd.net/privatmegleren/3f.jpg'
    },
    {
      url: 'https://reeltimeprod-ahebbuatfggte7f2.z01.azurefd.net/privatmegleren/4f.jpg'
    },
    {
      url: 'https://reeltimeprod-ahebbuatfggte7f2.z01.azurefd.net/privatmegleren/5f.jpg'
    },
    {
      url: 'https://reeltimeprod-ahebbuatfggte7f2.z01.azurefd.net/privatmegleren/6f.jpg'
    },
    {
      url: 'https://reeltimeprod-ahebbuatfggte7f2.z01.azurefd.net/privatmegleren/7f.jpg'
    },
    {
      url: 'https://reeltimeprod-ahebbuatfggte7f2.z01.azurefd.net/privatmegleren/8f.jpg'
    },
    {
      url: 'https://reeltimeprod-ahebbuatfggte7f2.z01.azurefd.net/privatmegleren/9f.jpg'
    }
  ];
  return (
    <>
      <StyledCarouselWrapper>
        <CarouselSection>
          <Container>
            <CarouselImageWrapper>
              <CarouselButtonRight className="right" onClick={prev} />

              {array.map((item, index) => {
                if (index === currentIndex) {
                  return (
                    <ImageWrapper key={index}>
                      <Image className="img" key={index} url={item.url} />
                    </ImageWrapper>
                  );
                } else {
                  return null;
                }
              })}
              <ImageCarouselBulletsWithNoAnimation
                className="bullets"
                images={array}
                select={select}
                currentIndex={currentIndex}
              />
              <CarouselButtonLeft className="left" onClick={next} />
            </CarouselImageWrapper>
          </Container>
        </CarouselSection>
      </StyledCarouselWrapper>
    </>
  );
};

const StyledCarouselWrapper = styled.div`
  @media all and (max-width: 1000px) {
    .left  {
      padding-right: 4em;
    }
    .right {
      padding-left: 4em;
    }
  }

  .left  {
    right: -70px;
  }
  .right {
    left: -70px;
  }
  .bullets {
    position: relative;
    width: 100%;
    max-width: 400px;
  }
`;

const Ingress = styled.div`
  display: flex;
  justify-content: center;
`;

const CarouselSection = styled(animated.div)`
  display: block;
  text-align: center;
`;

const CarouselImageWrapper = styled.div`
  position: relative;
  display: block;
`;

const ImageWrapper = styled.div`
  img {
    height: 39.9375em;
  }
`;

const HeroContentWrapper = styled.div``;

const DescriptionInsideTheHeroContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  height: 100%;

  @media all and (min-width: 800px) {
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
  }
`;

export default Saedberg;
